<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">SKU QR</p>
          </div>
          <div class="col-12">
            <b-tabs v-model="tabIndex" content-class="mt-1 p-3">
              <b-tab title="All" :title-link-class="linkClass(0)" active>
                <List />
              </b-tab>

              <b-tab title="Upload" :title-link-class="linkClass(1)"
                ><upload />
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upload from "@/components/Products/QRInfo/upload.vue";
import List from "@/components/Products/QRInfo/List.vue";
export default {
  components: { upload, List },
  data() {
    return {
      tabIndex: 0,
    };
  },
  methods: {
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ["text-main", "font-weight-bold"];
      } else {
        return ["text-muted"];
      }
    },
  },
};
</script>
