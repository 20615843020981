<template>
  <div class="container-fliud">
    <div class="row">
      <div class="col-12 m-0 p-0">
        <div class="row justify-content-end m-0 p-0">
          <div class="col-6 col-xl-2 text-xl-right">
            <div class="form-group form-group-sm  ">
              <input
                ref="fileupload"
                type="file"
                class="form-control-file form-control-file-sm"
                @change="onChange"
              />
            </div>
          </div>
          <div class="col-6 col-xl-3 text-xl-right">
            <button
              type="button"
              class="btn btn-sm bt-bnb mx-1"
              @click="Upload()"
              :disabled="items.length == 0"
            >
              บันทึกข้อมูล
            </button>
            <button
              type="button"
              class="btn btn-sm bt mx-1"
              :disabled="items.length == 0"
              @click="Clear()"
            >
              ยกเลิก
            </button>
            <button type="button" class="btn btn-sm bt-main mx-1">
              <a :href="file" download class="text-white"> ไฟล์ตัวอย่าง</a>
            </button>
          </div>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-12  ">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ &nbsp; &nbsp;<span
              v-if="numerror > 0"
              class="text-danger"
              >ข้อมูลถูกตัดออกเนื่องจากฟอร์แมตไม่ถูกต้อง ({{ numerror }}) >
              {{ msserror.join(",") }}</span
            >
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          :tbody-tr-class="rowClass"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Err)="row">
            <span v-if="row.item.Err.length > 0" class="text-twd">{{
              row.item.Err.join(",")
            }}</span>
          </template>
          <template #cell(Action)="row">
            <span
              type="button"
              class="text-twd"
              v-html="$svg_icon.remove"
              @click="remove(row)"
            ></span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateSKU_qr } from "@/api/Product.js";
export default {
  data() {
    return {
      file: require("@/files/SKUQR_Exam.xlsx"),
      totalRows: 0,
      numerror: 0,

      msserror: [],
      items: [],
      itemerror: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "skCode", label: "skCode" },
        {
          key: "Err",
          label: "",
        },
        {
          key: "Action",
          label: "",
        },
      ],
    };
  },
  methods: {
    async remove(data) {
      let { item, index } = data;
      let { skCode } = item;

      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        `คุณต้องการลบ ${skCode} / You need to delete ${skCode}?`
      );
      if (confirm) {
        this.items.splice(index, 1);

        let findindex = this.itemerror.findIndex((e) => e == item);

        if (findindex !== -1) {
          this.itemerror.splice(findindex, 1);
        }
        this.totalRows = this.items.length;
      }
    },
    async Upload() {
      try {
        if (this.items.length == 0) throw `กรุณาเลือกไฟล์ที่ต้องการอัพโหลด`;
        if (this.itemerror.length > 0) {
          throw `กรุณาเเก้ไขความผิดพลาด ${this.itemerror.length} รายการ เเล้วอัพโหลดไฟล์ใหม่อีกครั้ง`;
        }
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          "คุณต้องการบันทึกหรือไม่? / Do you want to save?"
        );
        if (confirm) {
          const data = { jdata: this.items };
          let getAPI = await updateSKU_qr(data);
          this.$serviceMain.showSuccessAlert(
            this,
            "อัพโหลดข้อมูลสำเร็จ / Complete"
          );
            await this.$store.dispatch(
              "ProductQRActions/call_get_productQR_list"
            );
          this.ClearTable();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async Clear() {
      let confirm = await this.$serviceMain.showConfirmAlert(
        this,
        "คุณต้องการยกเลิกหรือไม่? / Do you want to cancel?"
      );
      if (confirm) {
        this.ClearTable();
      }
    },
    async ClearTable() {
      this.$refs.fileupload.value = null;
      this.items = [];
      this.totalRows = 0;
      this.numerror = 0;
      this.msserror = [];
    },
    async onChange(event) {
      try {
        let readfile = await this.$serviceMain.ReadExcel(event.target.files[0]);
        let list = [];
        this.itemerror = [];
        let listerror = [];
        this.numerror = 0;
        this.msserror = [];
        this.totalRows = 0;

        if (readfile.length > 0) {
          let { id } = this.$store.getters["loginActions/GET_USER"];
          for await (var i of readfile) {
            let { skCode } = i;
            let check_sku = this.$serviceMain.chechnull(skCode);

            if (check_sku !== "") {
              let obj = {
                ...i,
                Active: 1,
                mode: "Add",
                UpdateBy: id,
                Err: [],
              };
              // ------ push data ------
              if (obj.Err.length > 0) {
                listerror.push(obj);
              } else {
                list.push(obj);
              }
            } else {
              this.numerror++;
              if (check_sku == "") {
                let mss = `ไม่มีข้อมูล SKU`;

                if (!this.msserror.includes(mss)) {
                  this.msserror.push(mss);
                }
              }
            }
          }
          this.items = [...listerror, ...list];
          this.itemerror = listerror;
          this.totalRows = list.length;
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    rowClass(item, type) {
      if (!item || type !== "row") return;

      if (item.Err.length > 0) return "table-danger ";
    },
  },
};
</script>

<style></style>
