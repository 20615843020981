<template>
  <div class="container-fliud">
    <div class="row">
      <div class="col-12 col-xl-4 mb-3">
        <div class="input-group input-group-sm   ">
          <div class="input-group-prepend input-group-sm">
            <select class="custom-select" v-model="mode">
              <option v-for="(e, i) in select_mode" :key="i" :value="e.key">
                {{ e.name }}
              </option>
            </select>
          </div>
          <input
            type="search"
            class="form-control form-control-sm"
            placeholder="ค้นหา / Seach"
            v-model="find"
            v-on:keyup.enter="Search()"
          />
          <div class="input-group-append">
            <button class="btn btn-sm bt-main" @click="Search()">
              ค้นหา
            </button>
          </div>
        </div>
      </div>
      <div class="row col-12">
        <div class="col-12  ">
          <p style="font-weight: bold;">
            จำนวน&nbsp;
            <span class="text-main">{{
              new Intl.NumberFormat().format(totalRows)
            }}</span>
            &nbsp;รายการ &nbsp; &nbsp;
          </p>
        </div>
      </div>
      <div class="col-12">
        <b-table
          :empty-text="'ไม่พบข้อมูล / No Data'"
          :fields="fields"
          :items="items"
          :current-page="page_num"
          :per-page="page_size"
          outlined
          hover
          show-empty
          responsive
          class="font-0-8s"
        >
          <template #cell(Active)="row">
            <span
              :class="[row.item.Active == 'Yes' ? 'badge-main' : 'badge-twd']"
              class="badge"
              >{{ row.item.Active }}</span
            >
          </template>
          <template #cell(Action)="row">
            <span
              v-if="row.item.Active === 'Yes'"
              type="button"
              class="text-twd"
              v-html="$svg_icon.remove"
              @click="status(row, 0)"
            ></span>
            <span
              v-else
              type="button"
              class="text-bnb"
              v-html="$svg_icon.Arrow_repeat"
              @click="status(row, 1)"
            ></span>
          </template>
        </b-table>
      </div>
      <div class="col-12">
        <div class="row justify-content-end">
          <div class="col-6 col-xl-1 input-group-prepend input-group-sm">
            <select class="custom-select" v-model="page_size">
              <option v-for="(e, i) in page_size_list" :key="i" :value="e">
                {{ e }}
              </option>
            </select>
          </div>
          <div class="col-6 col-xl-3">
            <b-pagination
              v-model="page_num"
              :total-rows="totalRows"
              :per-page="page_size"
              align="fill"
              size="sm"
              class="my-0"
            ></b-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { updateSKU_qr } from "@/api/Product.js";
export default {
  data() {
    return {
      mode: "skCode",
      select_mode: [
        { name: "ค้นหาด้วย SKU", key: "skCode" },
        { name: "ค้นหาด้วย Name TH", key: "prNameTH" },
        { name: "ค้นหาด้วย Name EN", key: "prNameEN" },
      ],
      totalRows: 0,
      find: "",
      items: [],
      itemlist: [],
      page_size: 10,
      page_size_list: [10, 15, 20, 25, 30],
      page_num: 1,
      fields: [
        { key: "skCode", label: "skCode" },
        { key: "prNameTH", label: "NameTH" },
        { key: "prNameEN", label: "NameEN" },
        { key: "Active", label: "Active" },
        { key: "lup", label: "LastUpdate" },
        { key: "udb", label: "UpdateBy" },
        {
          key: "Action",
          label: "",
        },
      ],
    };
  },
  watch: {
    find: function() {
      if (this.find == "") {
        this.unSearch();
      }
    },
    com_product_list: function(v) {
      this.set_table(v);
    },
  },
  computed: {
    com_product_list: function() {
      return this.$store.getters["ProductQRActions/GET_list"];
    },
  },
  mounted() {
    this.call_get_list();
  },
  methods: {
    async status(val, Active) {
      try {
        let { id } = this.$store.getters["loginActions/GET_USER"];
        let { skCode } = val.item;
        let mss = {
          en:
            Active === 0
              ? `You need to delete`
              : Active === 1
              ? `You need to enable`
              : `...`,
          th:
            Active === 0
              ? `คุณต้องการลบ`
              : Active === 1
              ? `คุณต้องการเปิดใช้งาน`
              : `...`,
        };
        let confirm = await this.$serviceMain.showConfirmAlert(
          this,
          `${mss.th} ${skCode} / ${mss.en} ${skCode}?`
        );

        if (confirm) {
          let dataList = [];
          let data = {
            Active: Active,
            skCode: skCode,
            mode: "status",
            UpdateBy: id,
          };
          dataList.push(data);
          await updateSKU_qr({ jdata: dataList });
          this.$serviceMain.showSuccessAlert(
            this,
            "อัพโหลดข้อมูลสำเร็จ / Complete"
          );

          this.call_get_list();
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    async call_get_list() {
      try {
        await this.$store.dispatch("ProductQRActions/call_get_productQR_list");
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
    set_table(data) {
      try {
        this.itemlist = data;
        this.items = data;

        this.totalRows = this.items.length;
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },

    unSearch() {
      this.items = this.itemlist;
      this.totalRows = this.items.length;
    },
    async Search() {
      let search = await this.$search.table(
        this.itemlist,
        this.find,
        this.mode
      );
      this.items = search.res;
      this.totalRows = search.len;
    },
  },
};
</script>

<style></style>
